import Vue from 'vue'
import App from './App.vue'
import VueRouter from "vue-router"
import router from "./routes";
import store from "./store/store";
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
import './assets/scss/app.scss'
import VueEllipseProgress from 'vue-ellipse-progress';
import Vue2Filters from 'vue2-filters';
import UUID from "vue-uuid";
import CKEditor from '@ckeditor/ckeditor5-vue2';
import {Plugin} from 'vue-fragment';
import filters from "./filters"

Vue.config.productionTip = false
// Make BootstrapVue available throughout your project
Vue.use(BootstrapVue)
// Optionally install the BootstrapVue icon components plugin
Vue.use(IconsPlugin)

Vue.use(VueRouter)

Vue.use(VueEllipseProgress)

Vue.use(Vue2Filters)

Vue.use(Plugin)

Vue.use(filters);

Vue.use(UUID);
Vue.use(CKEditor);
Vue.mixin({
    methods: {
        log: (msg, data) => {
            console.log(msg, data);
            return null;
        }
    }
});

new Vue({
    render: h => h(App),
    router,
    store
}).$mount('#app')
