import _ from 'lodash';

export default {
    namespaced: true,
    state: {
        messages: [],
        countUnreadMessages: 0
    },
    actions: {
        fetchMessages({commit, rootGetters}) {
            const selectedClaim = rootGetters['auth/user/claim/selectedClaim'];
            if (selectedClaim !== null) {
                return this.$axios.get(process.env.VUE_APP_API_URL + `/api/messages/list/${selectedClaim.id}`)
                    .then(({data}) => {
                        commit('setMessages', data.data);
                    });
            }
        },
        sendMessage({dispatch, commit, rootGetters}, message) {
            const selectedClaim = rootGetters['auth/user/claim/selectedClaim'];
            return this.$axios.post(process.env.VUE_APP_API_URL + `/api/messages/create`, {
                text: message,
                source: 1,
                claim_id: selectedClaim.id
            })
                .then(({data}) => commit('addMessage', data.data.message))
                .catch(error => dispatch('setError', error, {root: true}));
        },
        deleteMessage({dispatch, commit}, messageId) {
            return this.$axios.post(process.env.VUE_APP_API_URL + `/api/messages/delete`, {
                message_id: messageId
            })
                .then(() => commit('deleteMessage', messageId))
                .catch(error => dispatch('setError', error, {root: true}));
        },
        fetchCountUnreadMessages({dispatch, commit, rootGetters}) {
            const selectedClaim = rootGetters['auth/user/claim/selectedClaim'];
            return this.$axios.get(process.env.VUE_APP_API_URL + `/api/messages/unread-count/${selectedClaim.id}`)
                .then(({data}) => commit('setCountMessages', data.data.count))
                .catch(error => dispatch('setError', error, {root: true}));
        },
        clearMessages({commit}) {
            commit('clearMessages');
        }
    },
    mutations: {
        setMessages(state, messages) {
            state.messages = messages || [];
        },
        addMessage(state, message) {
            state.messages = [...state.messages, message];
        },
        deleteMessage(state, messageId) {
            let messages = state.messages;
            state.messages = messages.filter(item => item.id !== messageId);
        },
        setCountMessages(state, count) {
            state.countUnreadMessages = count;
        },
        clearMessages(state) {
            state.messages = [];
        }
    },
    getters: {
        messages(state) {
            return _.cloneDeep(state.messages);
        },
        countUnreadMessages(state) {
            return state.countUnreadMessages;
        }
    }
}