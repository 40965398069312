import _ from "lodash";
import router from '../../../routes';

export default {
    namespaced: true,
    state: {
        clients: [],
        selectedClient: null
    },
    actions: {
        fetchClients({commit, dispatch, state, rootGetters}, cache = true){
            if (cache && !_.isEmpty(state.clients)) {
                return;
            }
            return this.$axios
                .get( process.env.VUE_APP_API_URL + '/api/organization-list')
                .then(response => {
                    commit('setClients', response.data.data);
                    const clientId = router.currentRoute.params.client_id || router.currentRoute.query.organization_id || rootGetters['auth/organizationId'];
                    if (clientId) {
                        const client = response.data.data.filter(item => item.id === +clientId)[0];
                        if (client) {
                            commit('setSelectedClient', client);
                        }
                    }
                    else if (response.data.data.length === 1)
                    {
                        commit('setSelectedClient', response.data.data[0])
                    } else {
                        commit('loadRecentSelectedClient')
                    }

                })
                .catch(error => {
                    dispatch('setError', error, {root: true})
                });
        },
        selectClientById({commit}, clientId) {
            commit('setSelectedClientById', clientId);
        },
        selectClient({commit}, client) {
            commit('setSelectedClient', client);
        },
        clear({commit}) {
            commit('setClients', []);
            commit('setSelectedClient', null)
        },
    },
    mutations: {
        setClients(state, clients) {
            state.clients = clients
        },
        setSelectedClient(state, client) {
            state.selectedClient = client;

            if (client !== null) {
                localStorage.setItem('selected-client', JSON.stringify(client))
            }
        },
        setSelectedClientById(state, clientId) {
            const clients = state.clients.filter(item => item.id === clientId);
            if (clients) {
                const client = {...clients[0]};
                state.selectedClient = client;
                localStorage.setItem('selected-client', JSON.stringify(client));
            }
        },
        loadRecentSelectedClient(state) {
            const selectedClient = JSON.parse(localStorage.getItem('selected-client'));
            const clientIds = state.clients.map(client => client.id);

            if (selectedClient && clientIds.indexOf(selectedClient.id) >= 0) {
                state.selectedClient = selectedClient;
            }
        },

    },
    getters: {
        clients(state) {
            return _.cloneDeep(state.clients);
        },
        selectedClient(state) {
            return _.cloneDeep(state.selectedClient);
        }
    },
}