import _ from "lodash";

export default {
    namespaced: true,
    state: {
        pagination: {
            perPage: 10,
            currentPage: null,
            totalRows: null,
        },
        sorting: {
            sortKey: '',
            sortDesc: false,
        },
        items: [],
        selectedAll: false,
        loading: false,
    },
    actions: {
        async fetchProvider({commit, dispatch, state}, queryParams = {}) {
            commit('loading', true);
            queryParams.orderField = queryParams.orderField || "created_at";

            await this.$axios
                .get(process.env.VUE_APP_API_URL + '/api/documents/list/' + queryParams.claimId, {params: queryParams})
                .then(response => {
                    this.routerPushFromResponse(response);

                    let items = response.data.data.items;
                    items.forEach(function (item) {
                        item['selected'] = state.selectedAll;
                    })
                    commit('updateItems', items);
                    commit('updatePagination', {
                        perPage: response.data.data.itemsPerPage,
                        currentPage: response.data.data.page,
                        totalRows: response.data.data.count
                    });
                    commit('updateSorting', {
                        sortKey: '',
                        sortDesc: false
                    });
                    commit('loading', false);
                    commit('updateSelectedAll', false);
                    commit('clearSelection');
                })
                .catch(error => {
                    dispatch('setError', error, {root: true})
                    commit('loading', false);
                })
        },
        selectItem({commit, state}, item) {
            let items = _.cloneDeep(state.items);
            items.forEach(function (currentItem) {
                if (item.id === currentItem.id) {
                    currentItem.selected = item.selected
                }
                return currentItem;
            })
            commit('updateItems', items)
        },
        selectAll({state, commit}, canUploadSensitiveDocuments) {

            let items = _.cloneDeep(state.items);
            items.forEach(function (item) {
                if ((item.is_sensitive && !canUploadSensitiveDocuments) || item.source === 0)
                    return item

                item.selected = !state.selectedAll
                return item;
            })

            commit('updateSelectedAll', !state.selectedAll)
            commit('updateItems', items)
        },

    },
    mutations: {
        updateItems(state, items) {
            state.items = items;
        },
        updatePagination(state, pagination) {
            state.pagination = pagination;
        },
        updateSorting(state, sorting) {
            state.sorting = sorting;
        },
        updateSelectedAll(state, value) {
            state.selectedAll = value;
        },
        loading(state, value) {
            state.loading = value;
        },
        clearSelection(state) {
            state.items.forEach(function (currentItem) {
                currentItem.selected = false;
                return currentItem;
            })
        }
    },
    getters: {
        items(state) {
            return _.cloneDeep(state.items);
        },
        selectedItems(state) {
            let items = _.cloneDeep(state.items);
            return items.filter(function (item) {
                return item.selected === true
            })
        },
        pagination(state) {
            return _.cloneDeep(state.pagination);
        },
        sorting(state) {
            return _.cloneDeep(state.sorting);
        },
        loading(state) {
            return state.loading;
        },
    }
}