import _ from "lodash";

export default {
    namespaced: true,
    state: {
        list: []
    },
    actions: {
        fetchPositions({dispatch, commit}){
            return this.$axios
                .get( process.env.VUE_APP_API_URL + '/api/position-list')
                .then(response => {
                    commit('setPositionsList', response.data.data);
                })
                .catch(error => {
                    dispatch('setError', error, {root: true});
                });
        }
    },
    mutations: {
        setPositionsList(state, list) {
            state.list = list;
        }
    },
    getters: {
        listPositions(state) {
            return _.cloneDeep(state.list);
        }
    }
}