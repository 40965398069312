import _ from 'lodash';

export default {
    namespaced: true,
    state: {
        avatars: {},
        loading: new Set()
    },
    actions: {
        fetchAvatar({commit, dispatch, state}, userId) {
            if (_.isEmpty(state.avatars[`user${userId}`]) === false || state.loading.has(userId)) {
                return;
            }
            commit('loading', userId);
            this.$axios.get(process.env.VUE_APP_API_URL + `/api/user/avatar`, {responseType: 'blob', params: {userId}})
                .then(response => {
                    console.log('fetch avatar', userId);
                    if (response.data.size === 0) {
                        return;
                    }
                    const reader = new FileReader();
                    reader.readAsDataURL(response.data);
                    reader.onload = function () {
                        commit('setAvatar', {userId, avatar: reader.result});
                    }
                })
                .catch(error => {
                    dispatch('setError', error, {root: true})
                });
        }
    },
    mutations: {
        setAvatar(state, {userId, avatar}) {
            state.avatars = {[userId]: avatar, ...state.avatars};
        },
        loading(state, userId) {
            const loading = state.loading;
            loading.add(userId);
            state.loading = new Set(loading);
        }
    },
    getters: {
        getAvatar(state) {
            return userId => state.avatars[userId] || null;
        },
        isLoadingAvatar(state) {
            return (userId) => state.loading.has(userId);
        }
    }
}