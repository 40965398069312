export default {
    namespaced: true,
    actions: {
        deleteDocuments({dispatch}, ids){
            return this.$axios
                .post( process.env.VUE_APP_API_URL + '/api/documents/delete',{documents: ids})
                .then(() => {

                })
                .catch(error => {
                    dispatch('setError', error, {root: true})
                });
        }
    }
}