import store from "./store/store";
import VueRouter from "vue-router";

const ifNotAuthenticated = (to, from, next) => {
    if (!store.getters["auth/isAuthenticated"]) {
        next()
    }else{
        store.$axios.defaults.headers.common['Authorization'] = 'Bearer ' + store.getters["auth/auth"].access_token;
        next('/')
    }

}

const checkAuth = (type) => (to, from, next) => {
    if (store.getters["auth/isAuthenticated"]) {
        store.$axios.defaults.headers.common['Authorization'] = 'Bearer ' + store.getters["auth/auth"].access_token;
    }
    store.dispatch('auth/checkAuth').then(auth => {
        if (auth === true) {
            if (type === 'login') {
                next('/email-verification');
                return;
            }
            const redirect = store.getters["auth/redirect"];
            if (redirect) {
                next(redirect);
            } else {
                next('/documents');
            }
        } else {
            next();
        }
    })
}

const ifAuthenticated = (to, from, next) => {

    if (from.name === null) {
        if (to.query.organization_id) {
            store.dispatch('auth/setOrganizationId', to.query.organization_id);
        }
        store.dispatch('auth/redirectTo', {path: to.path, action: to.query.action});
    }

    if (store.getters["auth/isAuthenticated"]) {
        store.$axios.defaults.headers.common['Authorization'] = 'Bearer ' + store.getters["auth/auth"].access_token;
    }

    const tokenExpired = localStorage.getItem('token_expired');
    const currentTime = (new Date()).getTime();
    if (tokenExpired && currentTime + 10000 > tokenExpired) {
        store.dispatch('auth/refreshToken').then((data) => {
            if (data === false) {
                if (from.name === null) {
                    next('/login');
                }
                return;
            }
            store.$axios.defaults.headers.common['Authorization'] = 'Bearer ' + store.getters["auth/auth"].access_token;
            next();
        });
    } else {
        store.$axios.defaults.headers.common['Authorization'] = 'Bearer ' + store.getters["auth/auth"].access_token;
        store.dispatch('auth/checkAuth').then(auth => {
            if (auth === true) {
                next();
            } else {
                store.commit('auth/setAuth', {});
                next('/login');
            }
        })
    }
}

const routes = [
    {
        path: '/',
        name: 'dashboard',
        meta: {auth: true},
        component: () => import('./pages/Dashboard'),
        beforeEnter: ifAuthenticated
    },
    {
        path: '/documents',
        name: 'documents',
        meta: {auth: true},
        component: () => import('./pages/Documents'),
        beforeEnter: ifAuthenticated
    },
    {
        path: '/document/:id',
        name: 'document',
        meta: {auth: true},
        component: () => import('./pages/Document'),
        beforeEnter: ifAuthenticated
    },
    {
        path: '/preview/:type/:id',
        name: 'preview',
        meta: {auth: true},
        component: () => import('./pages/Preview'),
        beforeEnter: ifAuthenticated
    },
    {
        path: '/final-claim-pack/:client_id?/:claim_id?',
        name: 'final-claim-pack',
        meta: {auth: true},
        component: () => import('./pages/FinalClaimPack'),
        beforeEnter: ifAuthenticated
    },
    {
        path: '/my-team',
        name: 'my-team',
        meta: {auth: true},
        component: () => import('./pages/MyTeam'),
        beforeEnter: ifAuthenticated
    },
    {
        path: '/users',
        name: 'users',
        meta: {auth: true},
        component: () => import('./pages/Users'),
        beforeEnter: ifAuthenticated
    },
    {
        path: '/login',
        name: 'login',
        component: () => import('./pages/Login'),
        beforeEnter: checkAuth('login'),
    },
    {
        path: '/email-verification',
        name: 'email-verification',
        component: () => import('./pages/EmailVerify'),
        beforeEnter: checkAuth('email-verification')
    },
    {
        path: '/password-recovery',
        name: 'password-recovery',
        component: () => import('./pages/PasswordRecovery'),
        beforeEnter: ifNotAuthenticated,
    },
    {
        path: '/password-reset/:token',
        name: 'password-reset',
        component: () => import('./pages/PasswordReset'),
        beforeEnter: ifNotAuthenticated,
    },
    {
        path: '/account-creation/:token',
        name: 'account-creation',
        component: () => import('./pages/AccountCreation'),
        beforeEnter: ifNotAuthenticated
    },
    {
        path: '/subscription/notifications/stopped',
        name: 'notifications-stopped',
        component: () => import('./pages/SubscriptionChanged')
    },
    {
        path: '*',
        name: 'page-not-found',
        component: () => import('./pages/PageNotFound')
    }
]

const router = new VueRouter({
    routes: routes,
    mode: 'history'
})

router.beforeEach((to, from, next) => {
    store.dispatch('prevUrl', from.name);
    next();
})

export default router;
