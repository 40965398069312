import uploading from "./uploading";
import provider from "./provider";
import revision from "./revision";
import deleting from "./deleting";
import sensitive from "./sensitive";
import downloading from "./downloading";

export default {
    namespaced: true,
    modules: {
      uploading, provider, revision, deleting, sensitive, downloading
    },
    state: {

    },

}