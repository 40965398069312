import _ from 'lodash';

export default {
  namespaced: true,
  state: {
    countUnreadNotifications: 0,
    lastUnreadNotificationsCheck: 0,
    pagination: {
      perPage: 15,
      currentPage: 0,
      totalRows: null,
    },
    isLoading: false,
    selectedClient: null,
    rows: [],
    notifications: [],
    markAsReadLoading: false,
    watchingLoading: false,
    unread: null
  },
  actions: {
    fetchNotifications({commit, dispatch, state}, params) {
      if (state.pagination.totalRows !== null && state.notifications.length >= state.pagination.totalRows && (params.unread === undefined || state.unread === null)) {
        return;
      }
      commit('startLoadNotifications');
      let currentPage = state.pagination.currentPage + 1;
      if (params.unread !== undefined) {
        commit('setUnread', params.unread);
      } else {
        params.unread = state.unread;
      }
      params.page = currentPage;

      commit('setPage', currentPage);
      return this.$axios.get(process.env.VUE_APP_API_URL + `/api/notifications-center/list`, {params})
        .then(({data}) => {
          commit('finishLoadNotifications');
          commit('setNotifications', data.data.items)
          commit('setPagination', {
            perPage: 15,
            totalRows: data.data.count,
            currentPage: data.data.page
          });
          return data.data.items;
        }).catch(error => {
          commit('finishLoadNotifications');
          dispatch('setError', error, {root: true});
          return error;
        });
    },
    fetchCountUnreadNotifications({commit, dispatch}) {
      this.$axios.get(process.env.VUE_APP_API_URL + '/api/notifications-center/count-unread-notifications')
        .then(({data}) => {
          commit('countUnreadNotifications', data.data.count_unread_notifications);
          commit('setLastUnreadNotificationsCheck');
        }).catch(error => {
        dispatch('setError', error, {root: true});
        return error;
      });
    },
    selectClient({commit}, clientId) {
      commit('setCurrentPage', 1);
      commit('setSelectedClient', clientId);
    },
    startWatching({dispatch, commit}) {
      commit('startWatchingLoading');
      this.$axios.post(process.env.VUE_APP_API_URL + '/api/notifications-center/start-watching')
        .then(() => {
          commit('stopWatchingLoading');
          dispatch('auth/user/fetchUser', {}, {root: true});
        }).catch(error => {
        commit('stopWatchingLoading');
        dispatch('setError', error, {root: true});
        return error;
      });
    },
    stopWatching({dispatch, commit}) {
      commit('startWatchingLoading');
      this.$axios.post(process.env.VUE_APP_API_URL + '/api/notstate.pagifications-center/stop-watching')
        .then(() => {
          commit('stopWatchingLoading');
          dispatch('auth/user/fetchUser', {}, {root: true});
        })
        .catch(error => {
          commit('stopWatchingLoading');
          dispatch('setError', error, {root: true});
          return error;
        });
    },
    markAsRead({commit, dispatch}, {id, type}) {
      commit('startMarkAsReadLoading');
      this.$axios.post(process.env.VUE_APP_API_URL + '/api/notifications-center/mark-as-read', {id, type})
        .then(({data}) => {
          commit('stopMarkAsReadLoading');
          commit('setRead', {id: data.data.notification.id, read: data.data.notification.read_at});
          dispatch("fetchCountUnreadNotifications");
        })
        .catch(error => {
          commit('stopMarkAsReadLoading');
          dispatch('setError', error, {root: true});
          return error;
        });
    },
    markAsReadAll({commit, dispatch}) {
      commit('startMarkAsReadLoading')
      this.$axios.post(process.env.VUE_APP_API_URL + '/api/notifications-center/mark-as-read-all')
        .then(() => {
          commit('stopMarkAsReadLoading');
          commit('clearNotifications');
          dispatch('fetchNotifications', {page: 0});
          dispatch("fetchCountUnreadNotifications");
        })
        .catch(error => {
          commit('stopMarkAsReadLoading');
          dispatch('setError', error, {root: true});
          return error;
        });
    },
    selectNotification({commit}, item) {
      commit('setSelectedRow', item.id);
    },
    selectAllNotification({commit, state}, isSelectedAll) {
      let ids = [];
      if (isSelectedAll) {
        ids = state.allNotifications.map(row => row.id);
      }
      commit('selectAllNotifications', ids);
    },
    clearNotifications({commit}) {
      commit('clearNotifications');
    },
    paginate({commit}, page) {
      commit('setCurrentPage', page);
    },
    clear({commit}) {
      commit('clearNotifications');
    }
  },
  mutations: {
    setPage(state, page) {
      state.pagination = {...state.pagination, currentPage: page};
    },
    setUnread(state, unread) {
      state.unread = unread;
    },
    startLoadNotifications(state) {
      state.isLoading = true;
    },
    finishLoadNotifications(state) {
      state.isLoading = false;
    },
    setNotifications(state, notifications) {
      state.notifications = [...state.notifications, ...notifications];
    },
    setRows(state, rows) {
      state.rows = rows;
    },
    startMarkAsReadLoading(state) {
      state.markAsReadLoading = true;
    },
    stopMarkAsReadLoading(state) {
      state.markAsReadLoading = false;
    },
    startWatchingLoading(state) {
      state.watchingLoading = true;
    },
    stopWatchingLoading(state) {
      state.watchingLoading = false;
    },
    clearNotifications(state) {
      state.notifications = [];
      state.pagination = {...state.pagination, currentPage: 0};
    },
    setPagination(state, data) {
      state.pagination = data;
    },
    setSelectedRow(state, id) {
      state.selectedRows = [...state.selectedRows, id];
    },
    countUnreadNotifications(state, count) {
      state.countUnreadNotifications = count;
    },
    setCurrentPage(state, page) {
      state.pagination = {...state.pagination, currentPage: page};
    },
    setLastUnreadNotificationsCheck(state) {
      state.lastUnreadNotificationsCheck = Date.now() / 1000;
    },
    setRead(state, {id, read}) {
      let notifications = state.notifications;
      notifications = notifications.map(item => {
        if (item.id === id) {
          item.read_at = read;
        }
        return item;
      })
      state.notifications = [...notifications];
    }
  },
  getters: {
    curPagination: (state) => {
      return _.cloneDeep(state.pagination);
    },

    notifications: (state) => {
      return _.cloneDeep(state.notifications);
    },
    rows: (state) => {
      return _.cloneDeep(state.rows);
    },
    fields: (state) => {
      return _.cloneDeep(state.fields);
    },
    markAsReadLoading: (state) => {
      return state.markAsReadLoading;
    },
    watchingLoading: (state) => {
      return state.watchingLoading;
    },
    hasSelectedNotification: (state) => {
      return state.selectedRows.length > 0;
    },
    countUnreadNotifications: (state) => {
      return +state.countUnreadNotifications;
    },
    isSelectedRow: (state) => {
      return (id) => {
        return state.selectedRows.indexOf(id) > -1
      }
    },
    lastUnreadNotificationsCheck: (state) => {
      return state.lastUnreadNotificationsCheck;
    },
    isLoading: (state) => {
      return state.isLoading;
    },
    isUnread: (state) => {
      return state.unread;
    }
  }
}