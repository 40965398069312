export default {
    namespaced: true,
    actions: {
        changeSensitivity({dispatch}, data){
            return this.$axios
                .post( process.env.VUE_APP_API_URL + '/api/documents/set-sensitive  ',data)
                .then(() => {
                })
                .catch(error => {
                    dispatch('setError', error, {root: true})
                });
        }
    }
}