import _ from "lodash";
import router from '../../../routes';

export default {
    namespaced: true,
    state: {
        claims: [],
        selectedClaim: null
    },
    actions: {
        fetchClaims({commit, dispatch, rootGetters}, clientId){
            return this.$axios
                .post( process.env.VUE_APP_API_URL + '/api/claim-list',
                    {
                        filter: {organization_id: clientId}
                    })
                .then(response => {
                    commit('setClaims', response.data.data);
                    const currentRouteName = router.currentRoute.name;
                    const prevUrl = rootGetters['prevUrl'];
                    if (prevUrl === null && currentRouteName === 'preview') {
                        return;
                    }

                    const mayBeAutoSelectedClaims = response.data.data.filter(claim => claim.claim_status.is_open_claim);
                    const claimId = router.currentRoute.params.claim_id;
                    if (claimId) {
                        const claim = response.data.data.filter(item => item.id === +claimId)[0];
                        if (claim) {
                            commit('setSelectedClaim', claim);
                        }
                    }
                    else if (mayBeAutoSelectedClaims.length === 1)
                    {
                        commit('setSelectedClaim', mayBeAutoSelectedClaims[0])
                    } else {
                        commit('loadRecentSelectedClaim')
                    }

                })
                .catch(error => {
                    dispatch('setError', error, {root: true})
                });
        },
        fetchClaim({commit,}, claim_id) {
            return this.$axios.get(process.env.VUE_APP_API_URL + '/api/claim/' + claim_id)
                .then(response => {
                    commit('setClaim', response.data.data);
                })
        },
        selectClaim({commit, dispatch}, claim) {
            commit('setSelectedClaim', claim)
            if (claim !== null) {
                dispatch('messages/fetchCountUnreadMessages', null, {root: true});
            }
        },
        clear({commit}) {
            commit('setClaims', [])
            commit('setSelectedClaim', null)
        },
        finalClaimPackApprove({state, dispatch, commit}) {
            return this.$axios.put(process.env.VUE_APP_API_URL + `/api/claim/final-sign-off-approved`, {claimId: state.selectedClaim.id})
                .then(({data}) => {
                    commit('setSelectedClaim', data.data.claim);
                })
                .catch(error => {
                    dispatch('setError', error, {root: true})
                });
        },
    },
    mutations: {
        setClaim(state, claim) {
            const index = state.claims.findIndex((item) => item.id === claim.id);
            if (index === -1) {
                return;
            }
            state.claims[index] = claim;
            if (state.selectedClaim && state.selectedClaim.id === claim.id) {
                state.selectedClaim = claim
            }
        },
        setClaims(state, claims) {
            state.claims = claims;
        },
        setSelectedClaim(state, claim) {
            state.selectedClaim = claim;
            if (claim !== null) {
                localStorage.setItem('selected-claim', JSON.stringify(state.selectedClaim))
            }
        },
        loadRecentSelectedClaim(state) {
            const selectedClaim = JSON.parse(localStorage.getItem('selected-claim'));

            if (selectedClaim === null) {
                return;
            }

            if (selectedClaim) {

                const mayBeAutoSelectedClaims = state.claims.filter(claim => claim.claim_status.is_open_claim);

                const claimIds = state.claims.map(claim => claim.id);
                const recentSelectedClaimIndex = claimIds.indexOf(selectedClaim.id);

                if (recentSelectedClaimIndex >= 0) {

                    if (state.claims[recentSelectedClaimIndex].claim_status.is_open_claim)
                        state.selectedClaim = state.claims[recentSelectedClaimIndex];
                    else
                        state.selectedClaim = mayBeAutoSelectedClaims[0];
                }
            }

        },
        selectClaimById(state, claimId) {
            const claim = state.claims.filter(item => item.id === claimId)[0];

            if (claim) {
                state.selectedClaim = claim;
            }
        }
    },
    getters: {
        claims(state) {
            return _.cloneDeep(state.claims);
        },
        selectedClaim(state) {
            return _.cloneDeep(state.selectedClaim);
        },
        isClaimSelected(state) {
           return state.selectedClaim !== null;
        },
        isShowNotification(state) {
            if (_.isEmpty(state.selectedClaim.final_sign_off_status)) {
                return false;
            }
            const final_sign_off_status_name = state.selectedClaim.final_sign_off_status.name;
            return final_sign_off_status_name !== 'approval' &&
                   final_sign_off_status_name !== 'conditional_approval';
        },
        isClaimPackMycoApprove(state) {
            const final_sign_off_status_name = state.selectedClaim.final_sign_off_status.name;
            return final_sign_off_status_name === 'approval' || final_sign_off_status_name === 'conditional_approval';
        },
        isClaimPackApproved(state) {
            return state.selectedClaim.final_sign_off_pack_approved;
        },
        isClaimPackClosed(state) {
            if (_.isEmpty(state.selectedClaim))
                return false;

            return !state.selectedClaim.claim_status.is_open_claim;
        },
    }
}