import _ from "lodash";
import client from "./client";
import claim from "./claim";

export default {
    namespaced: true,
    modules: {
        client, claim
    },
    state: {
        user: null,
        permissions: [],
        avatar: null,
        redirectTo: null
    },
    actions: {
        fetchUser ({ dispatch, commit }) {
            dispatch('clearError', {}, { root: true })
            dispatch('getAvatar');
            return this.$axios
                .get(process.env.VUE_APP_API_URL + '/api/user-get')
                .then(response => {
                    commit('setUser', response.data.data)
                    let permissions = [];
                    if (!_.isEmpty(response.data.data.permissions)) {
                        response.data.data.permissions.forEach(function (permission) {
                            permissions.push(permission.permission)
                        })
                    }

                    commit('setPermissions', permissions);
                })
                .catch(error => {
                    dispatch('setError', error, { root: true })
                });
        },
        updateUser ({ dispatch, commit }, user) {
            dispatch('clearError', {}, { root: true });

            return this.$axios.put(process.env.VUE_APP_API_URL + `/api/user`, user)
                .then(({data}) => {
                    if (!_.isEmpty(data.data.user)) {
                        commit('setUser', data.data.user);
                    }
                    return true;
                })
                .catch(error => {
                    dispatch('setError', error, { root: true });
                    return false;
                });
        },
        uploadAvatar ({ dispatch }, avatar) {
            const formData = new FormData();
            formData.append('avatar', avatar);
            return this.$axios.post(process.env.VUE_APP_API_URL + `/api/user/avatar`, formData, { headers: { 'Content-Type': 'multipart/form-data' } })
                .then(() => {
                    dispatch('getAvatar');
                })
                .catch(error => {
                    dispatch('setError', error, { root: true })
                })
        },
        getAvatar({ dispatch, commit }) {
            this.$axios.get(process.env.VUE_APP_API_URL + `/api/user/avatar`, {responseType: 'blob'})
                .then(response => {
                    if (response.data.size === 0) {
                        return;
                    }
                    const reader = new FileReader();
                    reader.readAsDataURL(response.data);
                    reader.onload = function () {
                        commit('setAvatar', reader.result);
                    }
                })
                .catch(error => {
                    dispatch('setError', error, { root: true })
                });
        },
        clear({commit}) {
            commit('setUser', null)
            commit('setPermissions', [])
            commit('setAvatar', null)
        }
    },
    mutations: {
        setUser (state, user) {
            state.user = user
        },
        setAvatar (state, avatar) {
            state.avatar = avatar;
        },


        setPermissions (state, permissions) {
            state.permissions = permissions;
        }
    },
    getters: {
        user (state) {
            return _.cloneDeep(state.user)
        },
        userPermissions(state) {
            return _.map(state.user.permissions, 'permission');
        },
        profile(state) {
            return _.cloneDeep(state.user.profile)
        },
        username (state) {
            if (!state.user)
                return '';

            let firstname = '';
            let lastname = '';
            if (state.user.profile) {
                firstname = state.user.profile.firstname
            }
            if (state.user.profile) {
                lastname = state.user.profile.lastname
            }

            return `${firstname} ${lastname}`;
        },
        permissions (state) {
            return _.cloneDeep(state.permissions)
        },
        canInvite (state) {
            return state.permissions.includes('manage_users')
        },
        avatar (state) {
            return state.avatar;
        },
        canUploadDocuments(state) {
            return state.permissions.includes('sensitive_docs.update') || state.permissions.includes('non_sensitive_docs.update')
        },
        canUploadAllDocuments(state) {
            return state.permissions.includes('sensitive_docs.update') && state.permissions.includes('non_sensitive_docs.update')
        },
        canAccessSensitiveDocuments(state) {
            return state.permissions.includes('sensitive_docs.read') || state.permissions.includes('sensitive_docs.update')
        },
        canUploadSensitiveDocuments(state) {
            return state.permissions.includes('sensitive_docs.update');
        },
        canReadDocuments(state) {
            return state.permissions.includes('sensitive_docs.read') || state.permissions.includes('non_sensitive_docs.read')
        },
        canApproveFinalClaimPack(state) {
            return state.permissions.includes('final_claim.approve');
        },
        canReadFinalClaimPack(state) {
            return state.permissions.includes('final_claim.read');
        },
        canViewFinalClaimPack(state) {
          return state.permissions.includes('final_claim.read')
            || state.permissions.includes('final_claim.approve')
            || state.permissions.includes('final_claim.group_read');
        },
        userInFinalClaimGroupRead(state) {
            return state.permissions.includes('final_claim.group_read');
        },
        position (state, getters, rootState) {
            const position = rootState.user.position.list.find(item => item.id === state.user.position_id);
            return _.cloneDeep(position);
        },
        isCurrentUser (state) {
            return userId => state.user.id === userId;
        },
        isCurrentUserProfile (state) {
            if (!state.user.profile) {
                return false;
            }
            return profileId => state.user.profile.id === profileId;
        },
        watchingNotifications (state) {
            return state?.user?.profile?.watching_notifications;
        }
    }
}