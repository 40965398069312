import _ from 'lodash';

export default {
    namespaced: true,
    state: {
        pagination: {
            perPage: null,
            currentPage: null,
            totalRows: null,
        },
        sorting: {
            sortKey: '',
            sortDesc: false,
        },
        items: [],
    },
    actions: {
        async fetchProvider({dispatch, commit}, queryParams = {}) {
            queryParams.orderField = queryParams.orderField || "firstname";

            await this.$axios
                .get(process.env.VUE_APP_API_URL + '/api/user-list', {params: queryParams})
                .then(response => {
                    this.routerPushFromResponse(response);
                    commit('updateItems', response.data.data.items);
                    commit('updatePagination', {
                        perPage: response.data.data.itemsPerPage,
                        currentPage: response.data.data.page,
                        totalRows: response.data.data.count
                    });
                    commit('updateSorting', {
                        sortKey: '',
                        sortDesc: false
                    });
                })
                .catch(error => {
                    dispatch('setError', error, {root: true})
                })
        },
        clear({commit}) {
            commit('updateItems', []);
            commit('updatePagination', {
                perPage: null,
                currentPage: null,
                totalRows: null
            });
            commit('updateSorting', {
                sortKey: '',
                sortDesc: false
            });
        }
    },
    mutations: {
        updateItems(state, items) {
            state.items = items;
        },
        updatePagination(state, pagination) {
            state.pagination = pagination;
        },
        updateSorting(state, sorting) {
            state.sorting = sorting;
        }
    },
    getters: {
        items(state) {
            return _.cloneDeep(state.items);
        },
        pagination(state) {
            return _.cloneDeep(state.pagination);
        },
        sorting(state) {
            return _.cloneDeep(state.sorting);
        },
    }
}
