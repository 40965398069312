import _ from "lodash";

export default {
    namespaced: true,
    state: {
        user: {},
    },
    actions: {
        fetchUser({dispatch, commit}, userId){
            return this.$axios
                .get( process.env.VUE_APP_API_URL + '/api/user-get/' + userId)
                .then(response => {
                    let data = response.data.data;
                    if (_.isObject(data.organizations)) {
                        data.organizations = Object.keys(data.organizations).map(function (key) {
                            return data.organizations[key];
                        });

                    }
                    commit('setUser', data);
                })
                .catch(error => {
                    dispatch('setError', error, {root: true});
                });
        },
        updateUser({dispatch}, data) {
            return this.$axios
                .post( process.env.VUE_APP_API_URL + '/api/user-update', data)
                .then((response) => {
                    return response;
                })
                .catch(error => {
                    dispatch('setError', error, {root: true});
                    return error.response;
                });

        },
        clearUser({commit}) {
            commit('setUser', {})
        },
        clearError({dispatch}) {
            dispatch('setError', {}, {root: true})
        }
    },
    mutations: {
      setUser(state, user) {
          state.user = user;
      }
    },
    getters: {
        user(state) {
            return _.cloneDeep(state.user);
        },
        permissions(state) {
            let permissions = [];
            if (!_.isEmpty(state.user.permissions)) {
                permissions = _.cloneDeep(state.user.permissions).map(function (permission) {
                    return permission.permission;
                });
            }

            return permissions;
        }
    }
}