import axios from "axios";
import _ from 'lodash';

export default {
    namespaced: true,
    state: {
        loadingMessages: false,
        search: null,
        messages: [],
        users: [],
        avatars: {},
        urls: {
            profiles_myco: process.env.VUE_APP_API_URL + '/api/user/profiles/myco',
            document: {
                create: process.env.VUE_APP_API_URL + '/api/documents/message',
                fetch: process.env.VUE_APP_API_URL + '/api/documents/messages',
                users: process.env.VUE_APP_API_URL + '/api/documents/users',
            },
            "claim-pack": {
                create: process.env.VUE_APP_API_URL + '/api/claim-pack/message',
                fetch: process.env.VUE_APP_API_URL + '/api/claim-pack/messages',
                users: process.env.VUE_APP_API_URL + '/api/claim-pack/users'
            }
        }
    },
    mutations: {
        setThreads(state, {data}) {
            state.messages = data;
        },
        addThread(state, data) {
            state.threads.push(data);
        },
        setUsers(state, users) {
            state.users = users;
        },
        setAvatar(state, {userId, avatar}) {
            state.avatars = {...state.avatars, [userId]: avatar};
        },
        search(state, {search}) {
            state.search = search;
            // state.threads = [...state.threads];
        },
        clearSearch(state) {
            state.search = null;
            // state.threads = [...state.threads];
        },
        clearState(state) {
            state.threads = [];
            state.users = [];
        },
        startLoading(state) {
            state.loadingMessages = true;
        },
        stopLoading(state) {
            state.loadingMessages = false;
        }
    },
    actions: {
        createMessage({state}, {message, params, userProfiles}) {
            return axios.post(state.urls[params.type].create, {
                text: message,
                id: params.id,
                user_profiles: userProfiles
            });
        },
        fetchMessages({state, commit}, {params}) {
            if (params.setLoading === undefined || params.setLoading) {
                commit('startLoading');
                commit('clearSearch');
            }
            return axios.get(state.urls[params.type].fetch, {params: {id: params.id}})
                .then(({data}) => {
                    commit('setThreads', data);
                    commit('stopLoading');
                })
        },
        fetchUsers({state, commit}, {params}) {
            const users = axios.get(state.urls[params.type].users, {params: {id: params.id}})
                .then(({data}) => {
                    return data.data.users;
                });
            const profiles = axios.get(state.urls.profiles_myco)
                .then(({data}) => {
                    return data.data.profiles;
                })
            return Promise.all([users, profiles]).then(data => {
                const users = [...data[0], ...data[1]];
                commit('setUsers', users);
            })
        },
        fetchAvatar({state, commit, dispatch}, userId) {
            if (state.avatars[userId] === undefined) {
                commit('setAvatar', {avatar: null, userId});
                this.$axios.get(process.env.VUE_APP_API_URL + `/api/user/avatar?userId=${userId}`, {responseType: 'blob'})
                    .then(response => {
                        if (response.data.size === 0) {
                            return;
                        }
                        const reader = new FileReader();
                        reader.readAsDataURL(response.data);
                        reader.onload = function () {
                            commit('setAvatar', {avatar: reader.result, userId});
                        }
                    })
                    .catch(error => {
                        dispatch('setError', error, {root: true})
                    });
            }
        },
        addThread({commit}, data) {
            commit('addThread', data);
        },
        searchUser({commit}, data) {
            commit('search', data);
        },
        clearSearchUser({commit}, data) {
            commit('clearSearch', data);
        },
        getUserById({state}, userId) {
            return state.users.find(item => item.id === userId);
        },
        clear({commit}) {
            commit('clearState');
        }
    },
    getters: {
        search: (state) => {
            return _.cloneDeep(state.search);
        },
        messages: (state) => {
            return _.cloneDeep(Object.values(state.messages));
        },
        users: (state) => {
            return _.cloneDeep(state.users);
        },
        loadingMessages: (state) => {
            return state.loadingMessages;
        },
        searchUsers: (state) => {
            let users = [];

            return () => {
                const search = state.search.toLowerCase().replace(/\s/g, '')
                    .replace('&nbsp;', '');
                if (search === '') {
                    users = state.users;
                }

                users = state.users.filter((item) => {
                    const firstname = item.firstname.replace(/\s/g, '');
                    const lastname = item.lastname.replace(/\s/g, '');
                    if (`${firstname}${lastname}`.toLowerCase().indexOf(search) !== -1) {
                        return item;
                    }
                })

                return _.cloneDeep(users);
            }
        },
        avatar: (state) => {
            return (userId) => state.avatars[userId];
        }
    }
}