import _ from "lodash";

export default {
    namespaced: true,
    state: {
        invitation: {}
    },
    actions: {
        sendInvitation({dispatch, commit}, data) {
            commit('setInvitation', {});
            let url = null;
            if (data.mode === 'invitation') {
                url = process.env.VUE_APP_API_URL + '/api/invite-add';
            } else {
                url = process.env.VUE_APP_API_URL + '/api/reinvite'
            }
            return this.$axios
                .post( url, data)
                .then(response => {
                    commit('setInvitation', response.data.data);
                    return true;
                })
                .catch(error => {
                    dispatch('setError', error, {root: true});
                    return false;
                });
        },
        fetchInvitation({dispatch, commit}, id){
            dispatch('clearError', {}, {root: true});
            commit('setInvitation', {});
            return this.$axios
                .get( process.env.VUE_APP_API_URL + '/api/invite-check/' + id)
                .then(response => {
                    commit('setInvitation', response.data.data);
                })
                .catch(error => {
                    dispatch('setError', error, {root: true});
                });
        },
        activateInvitation({dispatch}, data){
            dispatch('clearError', {}, {root: true});
            return this.$axios
                .post( process.env.VUE_APP_API_URL + '/api/register-by-invite', data)
                .then(() => {

                })
                .catch(error => {
                    dispatch('setError', error, {root: true});
                });
        }
    },
    mutations: {
        setInvitation(state, invitation) {
            state.invitation = invitation;
        }
    },
    getters: {
        invitation(state) {
            return _.cloneDeep(state.invitation);
        }
    },
}