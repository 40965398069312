import _ from "lodash";

export default {
    namespaced: true,
    state: {
        listWhereCanInvite: []
    },
    actions: {
        fetchClientsWhereCanInvite({dispatch, commit}){
            return this.$axios
                .get( process.env.VUE_APP_API_URL + '/api/organization-list')
                .then(response => {
                    commit('setListWhereCanInvite', response.data.data);
                })
                .catch(error => {
                    dispatch('setError', error, {root: true})
                });
        }
    },
    mutations: {
        setListWhereCanInvite(state, list) {
            state.listWhereCanInvite = list;
        }
    },
    getters: {
        listWhereCanInvite(state) {
            return _.cloneDeep(state.listWhereCanInvite) || [];
        }
    }
}