import _ from "lodash";

export default {
    namespaced: true,
    state: {
        availablePermissions: {
            manage_users: [true, false],
            claim: ['open', 'all'],
            non_sensitive_docs: ['no_access', 'read', 'update'],
            sensitive_docs: ['no_access', 'read', 'update'],
            final_claim: ['no_access', 'read', 'approve']
        }


    },
    getters: {
        availablePermissions(state) {
            return _.cloneDeep(state.availablePermissions);
        }
    }
}