<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<script>
import {mapGetters, mapActions} from "vuex";

export default {
  name: 'App',
  components: {},
  computed: {
    ...mapGetters("auth", ["isAuthenticated"]),
    ...mapGetters('errors', ['errors', ])
  },
  methods: {
    ...mapActions("auth", ["logout"]),
    ...mapActions("pageNotifications", ["clear"]),
  },
  watch: {
    errors(errors) {
      for (let error of errors) {
        if (this.isAuthenticated && error.status === 401) {
          this.clear();
          return;
        }
      }
    }
  }
}
</script>

<style>

</style>
