import _ from 'lodash';


export default {
    namespaced: true,
    state: {
        actions: [],
    },
    actions: {
        fetch({commit, dispatch}, entityAttributes) {
            this.$axios
                .get(process.env.VUE_APP_API_URL + '/api/actions?type=' + entityAttributes.type + '&id=' + entityAttributes.id)
                .then(response => {
                    commit('setActions', response.data.data);
                })
                .catch(error => {
                    dispatch('seterror', error, {root: true})
                })
        },

    },
    mutations: {
        setActions(state, actions) {
            state.actions = actions || [];
        },
    },
    getters: {
        actions(state) {
            return _.cloneDeep(state.actions);
        },
    }
}