import _ from "lodash";

export default {
    namespaced: true,
    state: {
        files: [],
        progress: [],
        errors: [],
        cancel: [],
        successfullyUploadedFiles: [],
        replacedFiles: [],
    },
    actions: {
        addFile({commit, state}, file) {
            let files = _.cloneDeep(state.files);
            files.push(file);
            commit('setFiles', files)
        },
        upload({dispatch}, data) {
            const CancelToken = this.$axios.CancelToken
            const source = CancelToken.source();
            dispatch('updateCancelToken', {
                id: data.get('fileId'),
                value: source
            })
            const config = {
                onUploadProgress: function(progressEvent) {
                    var percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total)
                    dispatch('updateProgress', {id: data.get('fileId'), value: percentCompleted})
                },
                cancelToken: source.token
            }
            let url;
            if (data.get('fileAction') === 'replace') {
                url = process.env.VUE_APP_API_URL + '/api/documents/upload-new-version';
            }else{
                url = process.env.VUE_APP_API_URL + '/api/documents/upload';
            }
            return this.$axios
                .post( url, data, config)
                .then((response) => {
                    dispatch('removeFile', data.get('fileId'))
                    dispatch('updateSuccessfullyUploadedFiles', data)
                    dispatch('removeReplacedFile', data.get('fileId'))
                    return response;
                })
                .catch(error => {
                    if (!this.$axios.isCancel(error)) {
                        dispatch('setError', error, {root: true});
                        dispatch('updateErrors', {id: data.get('fileId'), value: error})
                    }
                    return error;
                });
        },
        removeFile({commit, state}, fileId) {
            let files = _.cloneDeep(state.files).filter(function (file) {
                return file.id !== fileId
            });
            commit('setFiles', files)
        },
        updateProgress({commit, state}, data) {
            let cloneProgress = _.cloneDeep(state.progress);
            let progress = cloneProgress.filter(function (item) {
                    return item.id !== data.id;
                });
            progress.push(data);
            commit('setProgress', progress)
        },
        updateErrors({commit, state}, data) {
            let errors = _.cloneDeep(state.errors);
            errors.push(data);
            commit('setErrors', errors);
        },
        updateCancelToken({commit, state}, data) {
            let cancel = _.cloneDeep(state.cancel);
            cancel.push(data)
            commit('setCancelToken', cancel)
        },
        updateSuccessfullyUploadedFiles({commit, state}, data) {
            let files = _.cloneDeep(state.successfullyUploadedFiles)
            files.push(data);
            commit('setSuccessfullyUploadedFiles', files)
        },
        addReplacedFile({commit, state}, data) {
            let files = _.cloneDeep(state.replacedFiles)
            files.push(data);
            commit('setReplacedFiles', files)
        },
        removeReplacedFile({commit, state}, fileId) {
            let files = _.cloneDeep(state.replacedFiles)
            let filteredFiles = files.filter(function (file) {
                return file.uuid !== fileId;
            })
            commit('setReplacedFiles', filteredFiles)
        },
        clearError({commit, state}, fileId) {
            let cloneErrors = _.cloneDeep(state.errors);
            let errors = cloneErrors.filter(function (item) {
                return item.id !== fileId;
            });

            commit('setErrors', errors)
        },
        clear({commit, state}) {
            const cancel = _.cloneDeep(state.cancel);
            cancel.forEach(function (item) {
                item.value.cancel();
            })
            commit('setFiles', []);
            commit('setProgress', []);
            commit('setErrors', []);
            commit('setCancelToken', []);
            commit('setSuccessfullyUploadedFiles', []);
            commit('setReplacedFiles', []);
        }
    },
    mutations: {
        setFiles(state, files) {
            state.files = files;
        },
        setProgress(state, progress) {
            state.progress = progress;
        },
        setErrors(state, errors) {
            state.errors = errors;
        },
        setCancelToken(state, token) {
            state.cancel = token;
        },
        setSuccessfullyUploadedFiles(state, files) {
            state.successfullyUploadedFiles = files;
        },
        setReplacedFiles(state, files){
            state.replacedFiles = files;
        }
    },
    getters: {
        files(state) {
            return _.cloneDeep(state.files)
        },
        progress(state) {
            return _.cloneDeep(state.progress)
        },
        errors(state) {
            return _.cloneDeep(state.errors)
        },
        cancel(state) {
            return _.cloneDeep(state.cancel)
        },
        successfullyUploadedFiles(state) {
            return _.cloneDeep(state.successfullyUploadedFiles)
        },
        replacedFiles(state) {
            return _.cloneDeep(state.replacedFiles)
        }
    }
}