export default {
    namespaced: true,
    actions: {
        downloadDocument({dispatch}, data){
            return this.$axios
                .get(
                    process.env.VUE_APP_API_URL + '/api/documents/download/' + data.id,
                    {
                        responseType: 'arraybuffer'
                    })
                .then((response) => {
                    let blob = new Blob([response.data])
                    let link = document.createElement('a')
                    link.href =  URL.createObjectURL(blob)
                    link.download = data.file_name
                    link.click()
                })
                .catch(error => {
                    console.log(error.message)
                    dispatch('setError', error, {root: true})
                });
        }
    }
}