import _ from "lodash";
import user from "./auth/user";
import router from "../../routes";

export default {
    namespaced: true,
    modules: {
        user
    },
    state: {
        auth: JSON.parse(localStorage.getItem('auth')) || {},
        error: {},
        email: null,
        redirectPath: null,
        redirectAction: null,
        verifyEmailError: null,
        canLoginRedirect: false,
        organizationId: null,
        showLoginPopup: false
    },
    actions: {
        checkAuth() {
            return this.$axios.post(process.env.VUE_APP_API_URL + '/api/auth/check')
                .then(({data}) => {
                    return data.data.auth;
                });
        },
        login({dispatch, commit}, data) {
            dispatch('clearError',{}, {root: true})
            commit('setAuth', {});
            localStorage.removeItem('auth');
            commit('setShowLoginPopup', false);
            return this.$axios
                .post(process.env.VUE_APP_API_URL + '/oauth/token', {
                    grant_type: process.env.VUE_APP_API_GRANT_TYPE,
                    client_id: process.env.VUE_APP_API_CLIENT_ID,
                    client_secret: process.env.VUE_APP_API_CLIENT_SECRET,
                    username: data.email,
                    password: data.password
                })
                .then(response => {
                    localStorage.setItem('auth', JSON.stringify(response.data));
                    let tokenExpiredAt = (new Date()).getTime() + response.data.expires_in * 1000;
                    localStorage.setItem('token_expired', tokenExpiredAt);
                    this.$axios.defaults.headers.common['Authorization'] = 'Bearer ' + response.data.access_token;
                    commit('setAuth', response.data);
                    router.push({name: 'email-verification', params: {email: data.email}}).catch(() => {});

                })
                .catch(error => {
                    dispatch('setError', error, {root: true});
                    router.push('/login').catch(() => {});
                });
        },
        refreshToken({commit, dispatch, state}) {
            dispatch('clearError',{}, {root: true});
            localStorage.removeItem('auth');
            return this.$axios
                .post(process.env.VUE_APP_API_URL + '/oauth/token', {
                    grant_type: 'refresh_token',
                    refresh_token: state.auth.refresh_token,
                    client_id: process.env.VUE_APP_API_CLIENT_ID,
                    client_secret: process.env.VUE_APP_API_CLIENT_SECRET,
                }).then(response => {
                    localStorage.setItem('auth', JSON.stringify(response.data));
                    let tokenExpiredAt = (new Date()).getTime() + response.data.expires_in * 1000;
                    localStorage.setItem('token_expired', tokenExpiredAt);
                    this.$axios.defaults.headers.common['Authorization'] = 'Bearer ' + response.data.access_token;
                    commit('setAuth', response.data);

                    return response.data.access_token;
                }).catch(() => {
                    dispatch('showLoginPopup');

                    dispatch('clear');
                    localStorage.removeItem('auth');
                    delete this.$axios.defaults.headers.common['Authorization'];

                    return false;
                });
        },
        emailVerify({commit}, code) {
            return this.$axios
                .post(`${process.env.VUE_APP_API_URL}/api/email-verify`, {code})
                .catch(e => {
                    commit('setEmailVerifyError', e.response.data.message);
                    if (e.response.status === 302 || e.response.status === 401) {
                        commit('setCanLoginRedirect', true);
                        commit('setAuth', null);
                    }

                    return false;
                });
        },
        logout({commit, dispatch}) {
            dispatch('clearError',{}, {root: true});
            return this.$axios
                .get(process.env.VUE_APP_API_URL + '/api/logout')
                .then(() => {
                    dispatch('user/claim/clear');
                    dispatch('user/client/clear');
                    dispatch('user/clear');
                    dispatch('user/provider/clear', null, {root: true})
                    dispatch('clear');
                })
                .catch(error => {
                    dispatch('setError', error, {root: true})
                })
                .finally(() => {
                        localStorage.removeItem('auth');
                        delete this.$axios.defaults.headers.common['Authorization'];
                        commit('setAuth', {})
                    }
                );
        },
        passwordRecovery({dispatch}, data) {
            dispatch('clearError',{}, {root: true})
            return this.$axios
                .post(process.env.VUE_APP_API_URL + '/api/reset-password/create', {
                    email: data.email
                })
                .then(() => {

                })
                .catch(error => {
                    dispatch('setError', error, {root: true})
                });
        },
        checkResetToken({dispatch, commit}, token) {
            dispatch('clearError',{}, {root: true})
            return this.$axios
                .get( process.env.VUE_APP_API_URL + '/api/reset-password/check/' + token)
                .then(response => {
                    commit('setEmail', response.data.data.email)
                })
                .catch(error => {
                    dispatch('setError', error, {root: true})
                    commit('setError', error)
                });
        },
        passwordReset({dispatch}, data) {
            dispatch('clearError',{}, {root: true})
            return this.$axios
                .post( process.env.VUE_APP_API_URL + '/api/reset-password/reset', data)
                .then(() => {
                    dispatch('login', {
                        email: data.email,
                        password: data.password
                    })
                })
                .catch(error => {
                    dispatch('setError', error, {root: true})
                });
        },
        checkPassword(obj, password) {
            return this.$axios.post(process.env.VUE_APP_API_URL + '/api/check-password', {password})
              .then(({data}) => {
                  return data.data.check_password;
              });
        },
        clearError({commit}) {
            commit('setError', {});
            commit('setError', {}, true);
        },
        clear({commit}) {
            commit('setAuth', {})
        },
        redirectTo({commit}, {path, action}) {
            commit('setRedirect', path);
            commit('setRedirectAction', action);
        },
        clearRedirect({commit}) {
            commit('setRedirect', null);
        },
        setOrganizationId({commit}, organizationId) {
            commit('setOrganization', organizationId);
        },
        showLoginPopup({commit}, isShow = true) {
            commit('setShowLoginPopup', isShow);
        }
    },
    mutations: {
        setAuth(state, auth) {
            state.auth = auth;
        },
        setEmail(state, email) {
            state.email = email;
        },
        setError(state, error) {
            state.error = error
        },
        setRedirect (state, redirect) {
            state.redirect = redirect;
        },
        setRedirectAction (state, action) {
            if (action) {
                state.redirectAction = action;
            }
        },
        setEmailVerifyError (state, error) {
            state.verifyEmailError = error;
        },
        setCanLoginRedirect (state, val) {
            state.canLoginRedirect = val;
        },
        setOrganization(state, organizationId) {
            state.organizationId = organizationId;
        },
        setShowLoginPopup(state, isShow = true) {
            state.showLoginPopup = isShow;
        }
    },
    getters: {
        isAuthenticated(state) {
            return !_.isEmpty(state.auth);
        },
        auth(state) {
            return _.cloneDeep(state.auth);
        },
        email(state) {
            return _.cloneDeep(state.email);
        },
        error(state) {
            return _.cloneDeep(state.error);
        },
        hasError(state) {
            return !_.isEmpty(state.error);
        },
        username(state) {
            const firstname = state.user.user.firstname || '';
            const lastname = state.user.user.lastname || '';
            return `${firstname} ${lastname}`;
        },
        redirect (state) {
            return state.redirect;
        },
        emailVerifyError (state) {
            return state.verifyEmailError;
        },
        canLoginRedirect (state) {
            return state.canLoginRedirect;
        },
        redirectAction (state) {
            return state.redirectAction;
        },
        organizationId (state) {
            return state.organizationId;
        },
        isShowLoginPopup (state) {
            return state.showLoginPopup;
        }
    },
}
