import _ from "lodash";
import provider from "./provider";
import position from "./position";
import client from "./client";
import editing from "./editing";
import permission from "./permission";
import avatars from "./avatars";

export default {
    namespaced: true,
    modules: {
        provider, position, client, editing, permission, avatars
    },
    state: {
        error: {}
    },
    actions: {
        deleteUser({dispatch}, id){
            return this.$axios
                .post( process.env.VUE_APP_API_URL + '/api/user-delete',{userId: id})
                .then(() => {

                })
                .catch(error => {
                    dispatch('setError', error, {root: true})
                });
        },
        clearError({commit}) {
            commit('setError', {});
        }
    },
    mutations: {
        setError(state, error) {
            state.error = error
        }
    },
    getters: {
        errorLogin(state) {
            return _.cloneDeep(state.error.login);
        }
    },
}
