import router from "../../routes";
import _ from "lodash";

export default store => {
    store.routerPushFromResponse = (response) => {
        const url = router.history.current.path;
        let params = {};

        if (!_.isEmpty(response.config.params)) {
            if (_.isInteger(response.config.params.page)) {
                params['page'] = response.config.params.page;
            }

            if (!_.isEmpty(response.config.params.orderField) && !_.isEmpty(response.config.params.orderDirection)) {
                params['orderField'] = response.config.params.orderField;
                params['orderDirection'] = response.config.params.orderDirection;
            }
        }

        if (params) {
            router.push(`${url}?${new URLSearchParams(params).toString()}`).catch(() => {});
        } else {
            router.push(url).catch(() => {});
        }
    }
}