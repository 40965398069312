import _ from "lodash";

export default {
    namespaced: true,
    state: {
        notifications: []
    },
    actions: {
        addNotification({commit, state}, data) {
            let notifications = _.cloneDeep(state.notifications);
            notifications.push({
                type: data.type,
                title: data.title,
                message: data.message
            })
            commit('setNotifications', notifications);
        },
        clearNotifications({commit}) {
            commit('setNotifications', []);
        }
    },
    mutations: {
        setNotifications(state, notifications) {
            state.notifications = notifications;
        }
    },
    getters: {
        notifications(state) {
            return _.cloneDeep(state.notifications)
        }
    }
}