export default {
    namespaced: true,
    state: {
        url: {
            'document': {
                'data': process.env.VUE_APP_API_URL + '/api/documents/get/',
                'file': {
                    'download': process.env.VUE_APP_API_URL + '/api/documents/download/',
                    'preview': process.env.VUE_APP_API_URL + '/api/documents/preview/'
                }
            },
            'claim-pack': {
                'data': process.env.VUE_APP_API_URL + '/api/claim-pack/get/',
                'file': {
                    'download': process.env.VUE_APP_API_URL + '/api/claim-pack/download/',
                    'preview': process.env.VUE_APP_API_URL + '/api/claim-pack/preview/'
                }
            },
        },
        document: null,
        file: null,
        claim: null,
        previewUrl: null,
        hasPreview: null,
        mustHavePreview: null,
        isLoading: false,
        isPreviewLoading: false,
        ignoreMimetype: [
            'image/jpeg',
            'image/png',
            'application/pdf'
        ]
    },
    actions: {
        setDefaultData({commit}) {
            commit('setFile', null)
            commit('setClaim', null)
            commit('setPreviewUrl', null)
            commit('setHasPreview', null)
        },
        fetchFileData({commit, dispatch, state}, file) {
            dispatch('setDefaultData')
            commit('fetchFileDataLoading', true);

            return this.$axios
                .get(state.url[file.type].data + file.id)
                .then(response => {
                    if (file.type === 'document')
                        commit('setDocument', response.data.data)

                    commit('setFile', response.data.data.file)
                    commit('setClaim', response.data.data.claim)
                    commit('fetchFileDataLoading', false);
                    if (response.data.data.preview_id === null) {
                        commit('setHasPreview', false)
                    }else {
                        commit('setHasPreview', true)
                    }
                    if (state.ignoreMimetype.includes(response.data.data.file.mimetype)) {
                        commit('setMustHavePreview', false)
                    }else {
                        commit('setMustHavePreview', true)
                    }
                    dispatch('fetchPreviewFile', file);
                    return response.data;
                })
                .catch(error => {
                    dispatch('setError', error, {root: true});
                    commit('fetchFileDataLoading', false);
                })
        },
        fetchPreviewFile({commit, dispatch, state}, file){
            commit('previewFileDataLoading', true);
            return this.$axios
                .get(
                    state.url[file.type].file.preview + file.id,
                    {
                        responseType: 'arraybuffer',
                    })
                .then((response) => {
                    let blob = new Blob([response.data])
                    let reader = new FileReader()
                    reader.onloadend = function () {
                        commit('setPreviewUrl', reader.result)
                        return reader.result
                    };
                    if (!state.hasPreview) {
                        reader.readAsDataURL(new File([blob], state.file.name, {type: state.file.mimetype}));
                    }else {
                        reader.readAsDataURL(new File([blob], 'test.pdf', {type: 'application/pdf'}));
                    }
                    commit('previewFileDataLoading', false);
                })
                .catch(error => {
                    const reader = new FileReader();

                    reader.onload = function() {
                        const errorMessage = JSON.parse(this.result);

                        for (let field in errorMessage.errors) {
                            for (let errorText of errorMessage.errors[field]) {

                                dispatch('setError', {
                                    response: {
                                        // statusText: errorText,
                                        data: {
                                            message: errorText
                                        }
                                    }
                                }, {root: true})

                            }
                        }
                    };

                    reader.readAsText(new Blob( [new Uint8Array(error.response.data)]));
                    commit('previewFileDataLoading', false);
                });
        },
        downloadFile({dispatch, state}, file){
            return this.$axios
                .get(
                    state.url[file.type].file.download + file.id,
                    {
                        responseType: 'arraybuffer'
                    })
                .then((response) => {
                    let blob = new Blob([response.data])
                    let link = document.createElement('a')
                    link.href =  URL.createObjectURL(blob)
                    link.download = file.name
                    link.click()
                })
                .catch(error => {
                    dispatch('setError', error, {root: true})
                });
        }
    },
    mutations: {
        setDocument(state, document) {
            state.document = document;
        },
        setFile(state, file) {
            state.file = file
        },
        setClaim(state, claim) {
            state.claim = claim
        },
        setPreviewUrl(state, url) {
            state.previewUrl = url
        },
        setHasPreview(state, hasPreview) {
            state.hasPreview = hasPreview
        },
        setMustHavePreview(state, mustHavePreview) {
            state.mustHavePreview = mustHavePreview
        },
        fetchFileDataLoading(state, isLoading) {
            state.isLoadin = isLoading;
        },
        previewFileDataLoading(state, isPreviewLoading) {
            state.isPreviewLoading = isPreviewLoading;
        }
    },
    getters: {
        document(state) {
            return state.document
        },
        file(state) {
            return state.file
        },
        claim(state) {
            return state.claim
        },
        previewUrl(state) {
            return state.previewUrl
        },
        ignoreMimetype(state) {
            return state.ignoreMimetype
        },
        hasPreview(state) {
            return state.hasPreview
        },
        mustHavePreview(state) {
            return state.mustHavePreview
        },
        isPdf(state) {
            return state.file.mimetype === 'application/pdf'
        },
        isLoading(state) {
            return state.isLoading;
        },
        isPreviewLoading(state) {
            return state.isPreviewLoading;
        }
    }
}