import _ from "lodash";
import Vue from 'vue';
import Vuex from 'vuex';
import { axios } from '@/plugins/axios'
import auth from "./modules/auth";
import user from "./modules/user/user";
import invitation from "./modules/invitation";
import errors from "./modules/errors";
import notifications from "./modules/notifications";
import document from "./modules/document/document";
import finalClaimPack from "./modules/finalClaimPack";
import provider from "./plugins/provider";
import messages from "./modules/messages";
import actions from "./modules/actions";
import preview from "./modules/preview"
import pageNotifications from "./modules/notifications/index";


const axiosPlugin = store => {

    axios.interceptors.response.use((response) => {

        return response
    }, (error) => {
        if (error.response && error.response.status === 401 && error.config.url.indexOf('oauth/token') === -1) {
            return store.dispatch('auth/refreshToken').then(accessToken => {
                if (accessToken) {
                    error.config.headers['Authorization'] = `Bearer ${accessToken}`;
                    return axios.request(error.config);
                }
            })
        }
        return Promise.reject(error);
    });
    store.$axios = axios
}

Vue.use(Vuex);

export default new Vuex.Store({
    modules: {
        auth, user, invitation, errors, notifications, document, finalClaimPack,
        messages, preview, actions, pageNotifications
    },
    state: {
        error: null,
        prevUrl: null
    },
    actions: {
        setError({dispatch}, error) {
            dispatch('errors/addError', error);
        },
        clearError({dispatch}) {
            dispatch('errors/clearErrors')
        },
        prevUrl({commit}, urlName) {
            commit('setPrevUrl', urlName);
        }
    },
    mutations: {
        setError(state, error) {
            state.error = error
        },
        setPrevUrl(state, urlName) {
            state.prevUrl = urlName;
        }
    },
    getters: {
        error(state) {
            return _.cloneDeep(state.error);
        },
        hasError(state) {
            return !_.isEmpty(state.error);
        },
        prevUrl(state) {
            return state.prevUrl;
        }
    },
    plugins: [axiosPlugin, provider],
    strict: process.env.NODE_ENV !== 'production'
})
